import React, { useState } from "react";
import Box from "@mui/material/Box";
import BasicSnackbar from "../../components/shared/material-components/BasicSnackbar";
import ConversationMessages from "../../components/ConversationMessages/ConversationMessages";
import ConversationFilter from "../../components/ConversationFilter/ConversationFilter";

const drawerWidth = 240;
const CampaignViewer = () => {
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState("campaign#cai3p0-general");
  const [account, setAccount] = useState("Cai3p0");
  const [customer, setCustomer] = useState("Cai3p0");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSelectCampaign = (e, campaign) => {
    e.preventDefault();
    // if (!campaign) return
    console.log(campaign);
    setCampaign("Cai3p0 General");
  };

  const onSelectAccount = (e, account) => {
    e.preventDefault();
    // if (!account) return
    console.log(account);
    setAccount(account);
    setCustomer("CAI3P0");
    setCampaign("Cai3p0 General");
  };

  const onSelectCustomer = (e, customer) => {
    e.preventDefault();
    console.log(customer);
    setCustomer(customer);
    setCampaign("");
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        marginTop: "0",
      }}
    >
      <ConversationFilter
        onError={() => setOpen(true)}
        account={account}
        customer={customer}
        campaign={campaign}
        onSelectAccount={onSelectAccount}
        onSelectCustomer={onSelectCustomer}
        onSelect={onSelectCampaign}
      />

      <ConversationMessages
        onError={() => setOpen(true)}
        campaign={campaign}
        account={account}
      />

      <BasicSnackbar
        open={open}
        severity="error"
        message="Data couldn't be fetched"
        onClose={handleClose}
      />
    </Box>
  );
};

export default CampaignViewer;
