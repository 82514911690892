import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutline, Error } from "@mui/icons-material";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";

const SignUpInvitation = ({ isSuperAdmin }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({});
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (!isSuperAdmin) {
      navigate("/signin");
    }
  }, [isSuperAdmin, navigate]);

  const createNewUser = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(username)) {
      setMessage({
        type: "error",
        content: "Please enter a valid email address.",
      });
      return;
    }

    try {
      await Auth.signUp(username, username, username);
      setMessage({
        type: "success",
        content:
          "A confirmation invitation has been sent to the email address you have entered.",
      });
    } catch (e) {
      setMessage({
        type: "error",
        content: "An error occurred during the process.",
      });
      console.error(e);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleRedirectToLogin = () => {
    navigate("/signin");
  };

  return isSuperAdmin ? (
    <Box
      className="login-overlay"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        className="login-card"
        sx={{
          padding: 4,
          maxWidth: 500,
          borderRadius: 8,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            createNewUser();
          }}
          sx={{ mt: 2 }}
        >
          <Typography variant="h6" gutterBottom>
            Sign Up Invitation
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            autoFocus
            value={username}
            onChange={handleUsernameChange}
          />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {message.content && (
              <Box display="flex" alignItems="center">
                {message.type === "success" ? (
                  <CheckCircleOutline sx={{ color: "green" }} />
                ) : (
                  <Error sx={{ color: "red" }} />
                )}
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    marginTop: "8px",
                    marginLeft: "10px",
                    width: "max-content",
                  }}
                >
                  {message.content}
                </Typography>
              </Box>
            )}
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={createNewUser}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
              >
                {message?.type === "success"
                  ? "Send another invitation"
                  : "Send invitation"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  borderColor: "black",
                  color: "black",
                  "&:hover": {
                    borderColor: "gray",
                  },
                }}
                onClick={handleRedirectToLogin}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  ) : null;
};

export default SignUpInvitation;
