import React from "react";
import { TextField, Typography } from "@mui/material";
import { FIELD_HEIGHTS } from "../utils/constants";

const PromptNameField = ({ name, commit, error, readOnly, onChange }) => (
  <>
    <Typography mb={-1} variant="body1" fontWeight="bold">
      Prompt Name <span style={{ color: "red" }}>*</span>{" "}
      {commit && <span style={{ color: "green" }}>{commit}</span>}
    </Typography>
    <TextField
      required
      id="prompt-name"
      placeholder="Prompt Name"
      value={name}
      error={!!error}
      helperText={error}
      onChange={(e) => onChange(e, "name")}
      InputProps={{
        readOnly: readOnly,
        sx: { fontSize: "14px", cursor: readOnly ? "not-allowed" : "text" },
      }}
      fullWidth
      variant="outlined"
      aria-required="true"
      aria-invalid={!!error}
      aria-describedby="prompt-name-error"
      sx={{
        backgroundColor: readOnly ? "#f0f0f0" : "white",
        "& .MuiInputBase-root": { height: FIELD_HEIGHTS.DEFAULT },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": { borderColor: "black" },
        },
      }}
    />
    <Typography variant="caption" color="textSecondary" mt={-1.5}>
      Words will be automatically separated by underscores
    </Typography>
  </>
);

export default PromptNameField;
