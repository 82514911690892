import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Unstable_Grid2";
import ConversationList from "../ConversationList/ConversationList";
import ChatMsg from "./ChatMsg";
import _ from "lodash";

const ConversationMessages = ({onError, campaign, account}) => {
  const [messages, setMessages] = useState([])
  const [conversation, setConversation] = useState('')

  const onSelectConversation = (conversation, messages) => {
    console.log(conversation)
    setMessages(messages)
    setConversation(conversation)
  }

  useEffect(() => {
    if (!account) {
      setMessages([])
    }
  }, [account])

  useEffect(() => {
    if (!campaign) {
      setMessages([])
    }
  }, [campaign])

  return (
      <Grid container spacing={2} xs={12} sm={18} md={18} lg={12} sx={{display: 'flex', flexDirection: 'row'}}>
        {(!_.isEmpty(campaign)) ? (
          <Grid xs={12} sm={5} md={7} lg={4} sx={{display: campaign ? 'flex' : 'none', order: '0', alignSelf: 'auto'}}>
            <ConversationList campaign={campaign} account={account} onError={onError} onSelect={onSelectConversation}/>
          </Grid>
        ) : ('')}
        {(!_.isEmpty(messages)) ? (

          <Grid xs={11} sm={6} md={11} lg={8} sx={{
            order: '1',
            alignSelf: 'auto',
            maxHeight: '100vh',
            overflowY: 'auto'
          }}>

            <Box
              sx={{
                display: conversation ? 'flex' : 'none',
                m: 1,
                bgcolor: 'primary.light',
                p: 2,
                color: 'white',
                border: '1px solid',
                borderColor: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 1,
                fontSize: '0.875rem',
                fontWeight: '700',
                backgroundColor: '#015d86',
              }}>
              {conversation.primary}

            </Box>

            {messages.map((item, index) => (
              <ChatMsg
                avatar={""}
                side={item.role === 'CONTACT' ? 'left' : 'right'}
                messages={[item]}
              />
            ))}

          </Grid>
        ) : ('')}
      </Grid>
  )
}

export default ConversationMessages