import React, { useCallback, useState } from "react";
import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";

const questions = [
  { id: 1, text: "Please, help me build my case study " },
  { id: 2, text: "Can you help me with the introduction " },
  { id: 3, text: "Please tell me what sections I'm missing " },
  { id: 4, text: "Lets review my document " },
  { id: 5, text: "I want to change the Company Overview " },
  { id: 6, text: "Generate a draft of my Case Study " },
];

const SuggestedQuestionsButton = ({ handleSelectQuestion }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenQuestions = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseQuestions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleQuestionClick = useCallback(
    (item) => {
      handleCloseQuestions();
      handleSelectQuestion(item);
    },
    [handleSelectQuestion, handleCloseQuestions]
  );

  return (
    <>
      <Tooltip title="Suggested questions" arrow placement="top">
        <Button
          variant="contained"
          onClick={handleOpenQuestions}
          sx={{
            width: "0px",
            height: "31px",
            display: "flex",
            minWidth: "0px",
            marginTop: "3px",
            marginRight: "-4px",
            borderRadius: "20px",
            backgroundColor: "#015d86",
            "&:hover": {
              backgroundColor: "#1a749c",
            },
          }}
        >
          <Help sx={{ fontSize: "20px" }} />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseQuestions}
        sx={{
          display: "flex",
          marginTop: "-58px",
          marginLeft: "-15px",
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="fontWeightBold"
          sx={{
            padding: "2px",
            color: "black",
            fontSize: "14px",
            marginLeft: "10px",
          }}
        >
          Suggested Questions
        </Typography>
        {questions.map((question) => (
          <MenuItem
            key={question.id}
            App
            Logo
            onClick={() => handleQuestionClick(question)}
            sx={{ color: "black", fontSize: "14px" }}
          >
            {question.id}. {question.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SuggestedQuestionsButton;
