import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIELD_HEIGHTS } from "../utils/constants";

const PromptInputVariables = ({
  variables,
  onAddVariable,
  onUpdateVariable,
}) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Typography mb={-1} mt={1} variant="body1" fontWeight="bold">
        Input Variables
      </Typography>
      <Box
        gap={2}
        height="160px"
        display="flex"
        overflow="auto"
        maxHeight="100px"
        paddingTop="10px"
        flexDirection="column"
      >
        <Scrollbars style={{ height: "100%" }}>
          {variables.map((variable, index) => (
            <Box
              key={index}
              display="flex"
              gap={1}
              sx={{ marginBottom: "10px" }}
            >
              <TextField
                placeholder="Name"
                value={variable.name}
                onChange={(e) => onUpdateVariable(index, true, e.target.value)}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: FIELD_HEIGHTS.DEFAULT,
                    fontSize: "12px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                }}
              />
              <TextField
                placeholder="Value"
                value={variable.value}
                onChange={(e) => onUpdateVariable(index, false, e.target.value)}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: FIELD_HEIGHTS.DEFAULT,
                    fontSize: "12px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                }}
              />
            </Box>
          ))}
        </Scrollbars>
      </Box>
      <Button
        onClick={onAddVariable}
        startIcon={
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: "16px" }} />
        }
        sx={{
          width: isMobileOrTablet ? "100%" : "20%",
          display: "flex",
          alignSelf: "center",
          textTransform: "none",
        }}
      >
        Add Input Variable
      </Button>
    </>
  );
};

export default PromptInputVariables;
