import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutline } from "@mui/icons-material";
import project_logo from "../../../images/ProjectLogo.png";

const SignIn = ({ onSignIn }) => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300);
  const [cognitoUser, setCognitoUser] = useState({});
  const [otpExpired, setOtpExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationOpen, setNotificationOpen] = useState(false);

  useEffect(() => {
    if (notificationOpen) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [notificationOpen]);

  useEffect(() => {
    let timer;
    if (otpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setOtpExpired(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent, timeLeft]);

  const OTPlogin = async () => {
    try {
      if (otpExpired) {
        setErrorMessage("The OTP code has expired. Please request a new one.");
        return;
      }

      await Auth.sendCustomChallengeAnswer(cognitoUser, OTP);
      handleRedirectLoggedUser();
    } catch (e) {
      console.error(e);
      setErrorMessage("The OTP code entered is invalid. Please try again.");
    }
  };

  const resendOTP = async () => {
    try {
      const res = await Auth.signIn(username, username);
      setCognitoUser(res);
      setOtpSent(true);
      setTimeLeft(300);
      setOtpExpired(false);
      setErrorMessage("");
    } catch (e) {
      console.error(e);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleLogin = async () => {
    try {
      setNotificationOpen(true);
      const res = await Auth.signIn(username, username);
      setCognitoUser(res);
      setTimeLeft(300);
      setOtpExpired(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleRedirectLoggedUser = () => {
    onSignIn("logged");
    navigate("/onboarding");
  };

  const handleCloseNotification = () => {
    setOtpSent(true);
    setNotificationOpen(false);
  };

  return (
    <Box
      className="login-overlay"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        className="login-card"
        sx={{
          padding: 4,
          maxWidth: 500,
          borderRadius: 8,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        {!otpSent && (
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
            sx={{ mt: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              Sign In
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={project_logo}
                alt="App Logo"
                style={{ maxWidth: "30%", height: "30%" }}
              />
            </Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ marginTop: "16px" }}
            >
              To log in, please enter your Internship program email address.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              We will send you a One-Time Password (OTP) to verify your
              identity.
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              value={username}
              onChange={handleUsernameChange}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "gray",
                },
              }}
              onClick={handleLogin}
            >
              Log In
            </Button>
          </Box>
        )}
        {!notificationOpen && otpSent && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              OTPlogin();
            }}
          >
            <Typography variant="h6" gutterBottom>
              One Time Password
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ marginTop: "16px" }}
            >
              Please enter the OTP sent to your registered email.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              This OTP is valid for 5 minutes.
            </Typography>
            {otpSent && <Typography>{formatTime(timeLeft)}</Typography>}
            <TextField
              fullWidth
              margin="normal"
              label="OTP"
              value={OTP}
              onChange={handleOTPChange}
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{
                background: "black",
                color: "white",
                "&:hover": {
                  background: "gray",
                },
              }}
              onClick={OTPlogin}
            >
              Submit OTP
            </Button>
            {errorMessage && (
              <Typography variant="subtitle2" color="error" sx={{ mt: 1 }}>
                {errorMessage}
              </Typography>
            )}
            {otpExpired && (
              <Button
                fullWidth
                variant="contained"
                onClick={resendOTP}
                sx={{ marginTop: "12px" }}
              >
                Resend OTP
              </Button>
            )}
          </form>
        )}
        <Dialog
          open={notificationOpen}
          onClose={handleCloseNotification}
          PaperProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {loading ? (
                <CircularProgress size={24} sx={{ marginRight: 1 }} />
              ) : (
                <CheckCircleOutline sx={{ color: "green", marginRight: 1 }} />
              )}
              <Typography variant="h6">Email Sent</Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2" gutterBottom>
              An email has been sent to your registered email address.
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: "-10px" }}>
              Please check your inbox and enter the OTP to log in.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNotification} color="inherit">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default SignIn;
