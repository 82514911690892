import React from "react";
import parse, { domToReact } from "html-react-parser";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

const HtmlParser = ({ html }) => {
  const options = {
    replace: ({ name, children }) => {
      if (name === "p") {
        return (
          <Typography
            variant="body1"
            sx={{ margin: "10px", marginTop: "15px" }}
          >
            {domToReact(children)}
          </Typography>
        );
      }
      if (name === "strong") {
        return (
          <Typography
            variant="body1"
            component="span"
            fontWeight="bold"
            sx={{ margin: "10px" }}
          >
            {domToReact(children)}
          </Typography>
        );
      }
      if (name === "ul") {
        return (
          <List sx={{ margin: "10px" }}>
            {domToReact(children).map((child, index) => (
              <ListItem
                key={index}
                sx={{
                  marginLeft: "20px",
                  marginBottom: "-18px",
                  listStyleType: "disc",
                }}
              >
                <ListItemText primary={child} />
              </ListItem>
            ))}
          </List>
        );
      }
      if (name === "li") {
        return (
          <ListItem sx={{ margin: "10px" }}>
            <ListItemText primary={domToReact(children)} />
          </ListItem>
        );
      }
    },
  };

  return <Box sx={{ margin: "10px" }}>{parse(html, options)}</Box>;
};

export default HtmlParser;
