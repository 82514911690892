import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  deleteAgent,
  getPlaygroundConversation,
  putAgent,
} from "../../graphql/queries";
import useStore from "../../hooks/useStore";
import DataFetcher from "../../utils/DataFetcher";
import ChatBox from "../../components/ChatBox/ChatBox";
import UseNotification from "../../utils/UseNotification";
import AssistantDetails from "../Playground/AssistantDetails";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";

const Playground = () => {
  const theme = useTheme();
  const location = useLocation();
  const { authStore } = useStore();
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [dataMessages, setDataMessages] = useState([]);
  const [agentDeleted, setAgentDeleted] = useState("");
  const [currentAgent, setCurrentAgent] = useState({});
  const [isGroqAgent, setIsGroqAgent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [updateKey, setUpdateKey] = useState(Date.now());
  const [selectedAgent, setSelectedAgent] = useState({});
  const [currentThread, setCurrentThread] = useState("");
  const [uploadedStatus, setUploadedStatus] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const selectedPrompt = location.state?.selectedPrompt || null;
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  } = UseNotification();

  const useConfirmationDialog = () => {
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
      setOpenConfirmation(true);
    };

    const handleCloseConfirmation = () => {
      setOpenConfirmation(false);
    };

    return {
      openConfirmation,
      handleOpenConfirmation,
      handleCloseConfirmation,
    };
  };

  const { openConfirmation, handleOpenConfirmation, handleCloseConfirmation } =
    useConfirmationDialog();

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("CustomAgentMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  useEffect(() => {
    if (!authStore.userId) return;

    const initialize = async () => {
      const applicantId = authStore.userId;
      const getFiles = await dataFetcher.fetchFiles(applicantId);
      setUserId(applicantId);
      setFiles(getFiles);
    };

    initialize();
  }, [authStore.userId]);

  useEffect(() => {
    localStorage.setItem("CustomAgentMessages", JSON.stringify(messages));
  }, [messages]);

  const handlePutAgent = async (data) => {
    const { instructions, name, selectedAgentId } = data;
    const ids = files
      .filter((file) => file.file_name !== "case_study_placeholder")
      .map((file) => file.file_name);

    const params = {
      customer_id: "cai3p0",
      id: selectedAgentId || "",
      applicant_id: userId,
      prompt: instructions,
      provider: "openai",
      name: name,
      knowledge_base: ids,
    };

    try {
      const response = await API.graphql({
        query: putAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setIsGroqAgent(false);
      const typeMessage =
        Object.keys(selectedAgent).length === 0 ? "created" : "updated";

      handleOpenNotification(
        "Success",
        `Agent successfully ${typeMessage}.`,
        "success"
      );

      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred in the process, verify and try again.",
        "error"
      );
      console.error("Error saving/editing agent to database:", error);
    }
  };

  const handleDeleteAgent = async () => {
    handleCloseConfirmation();
    const params = {
      customer_id: "cai3p0",
      id: selectedAgent.id,
    };

    try {
      const response = await API.graphql({
        query: deleteAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      handleOpenNotification(
        "Success",
        "Agent successfully deleted.",
        "success"
      );
      setSelectedAgent({});
      setAgentDeleted("Yes");
      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred while deleting the agent.",
        "error"
      );
      console.error("Error deleting agent from the database:", error);
      setAgentDeleted("No");
    }
  };

  const handleSelectAgent = async (agent) => {
    setAgentDeleted("");
    setIsGroqAgent(false);
    setSelectedAgent(agent);
  };

  const uploadFile = useCallback(
    async (event) => {
      setIsUploadingFile(true);
      const uploadedFiles = await handleFileUpload(event.target.files, userId);
      await dataFetcher.saveFilesToDatabase(uploadedFiles, userId);
      setUpdateKey(Date.now());
      setIsUploadingFile(false);
    },
    [dataFetcher, userId]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
          });
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      if (uploadedFiles.length) {
        setUploadedStatus("success");
      }
      return uploadedFiles;
    } catch (error) {
      setUploadedStatus("error");
      throw new Error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newSentMessage = { message: newMessage, type: "sent" };
    const newLoadingMessage = {
      message: "",
      type: "received",
      isLoading: true,
    };

    setCurrentAgent(
      Object.keys(selectedAgent).length === 0 ? selectedAgent : currentAgent
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      newSentMessage,
      newLoadingMessage,
    ]);

    setShowSpinner(true);

    const handleApiError = (messages) => {
      return messages.map((msg) =>
        msg.isLoading
          ? {
              ...msg,
              message: "Oops! Something went wrong. Please try again.",
              isLoading: false,
            }
          : msg
      );
    };

    const params = {
      customer_id: "cai3p0",
      agent_id: selectedAgent.id,
      message: newMessage,
      thread: Object.keys(selectedAgent).length === 0 ? currentThread : "",
      user_id: userId,
    };

    console.log("params: ", params);

    try {
      let openAiAnswer = null;

      let local_msgs = [];
      local_msgs = _.union(messages, []);
      console.log("Messages: ", messages);

      let resultConversationOpenAi = null;
      try {
        resultConversationOpenAi = await API.graphql({
          query: getPlaygroundConversation,
          variables: params,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (!resultConversationOpenAi.errors?.length) {
          const apiResponse =
            resultConversationOpenAi.data.getPlaygroundConversation;

          const parseResponseBody = (responseBody) => JSON.parse(responseBody);

          local_msgs = _.union(local_msgs, [newSentMessage, newLoadingMessage]);
          let tmpMsg = "";
          let initialMessages = [...local_msgs];
          let isStreaming = false;
          setMessages(local_msgs);

          let assistantResponseSub = API.graphql({
            ...graphqlOperation(onChatAssistantResponse, {
              id: apiResponse.id,
            }),
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).subscribe({
            next: ({ provider, value }) => {
              const subApiResponse = value.data.onChatAssistantResponse;
              console.log("subApiResponse: ", subApiResponse);
              const body = parseResponseBody(subApiResponse.body);
              if (body.error) {
                const newMessages = initialMessages.map((msg) =>
                  msg.isLoading
                    ? {
                        ...msg,
                        message: body.error,
                        isLoading: false,
                      }
                    : msg
                );
                setMessages(newMessages);
                return;
              }
              openAiAnswer = body.answer;
              setShowSpinner(false);
              const feedbackData = {
                user_id: userId,
                vote: "DOWN",
                agent_id: body.agent,
                message: body.answer,
                message_id: body.message_id,
                campaign: "",
                comment: "Comment",
              };
              setDataMessages((prevMessages) => [
                ...prevMessages,
                feedbackData,
              ]);

              if (subApiResponse.status === "done") {
                if (!isStreaming) {
                  const filteredMessages = initialMessages.filter(
                    (msg) => !msg.isLoading
                  );
                  const newMessages = [
                    ...filteredMessages,
                    { message: openAiAnswer, type: "received" },
                  ];
                  setMessages(newMessages);
                  initialMessages = [...newMessages];
                  setNewMessage("");
                  setCurrentThread(`"${body.thread_id}"`);
                }
                assistantResponseSub.unsubscribe();
                tmpMsg = "";
                initialMessages = [...messages];
                isStreaming = false;
                setShowSpinner(false);
              } else {
                isStreaming = true;
                tmpMsg += openAiAnswer;
                const newMessages = initialMessages.map((msg) =>
                  msg.isLoading
                    ? {
                        ...msg,
                        message: tmpMsg,
                        isLoading: false,
                      }
                    : msg
                );
                setMessages(newMessages);
              }
            },
            error: (error) => {
              console.warn(error);
              setShowSpinner(false);
              setMessages(handleApiError(local_msgs));
            },
          });
        }
      } catch (err) {
        console.log(err);
        setShowSpinner(false);
        setMessages(handleApiError(local_msgs));
      }
    } catch (error) {
      console.error(error);

      if (
        error.errors &&
        error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
      ) {
        setMessages((prevMessages) => handleApiError(prevMessages));
      }
    }
  };

  const handleClearMessages = () => {
    setMessages([]);
    localStorage.removeItem("AdminChatMessages");
  };

  const handleConfirmationDialog = () => {
    handleOpenConfirmation();
  };

  const handleRejectConfirmation = () => {
    setAgentDeleted("No");
    handleCloseConfirmation();
  };

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={isMobileOrTablet ? "column" : "row"}
    >
      <AssistantDetails
        selectedPrompt={selectedPrompt}
        files={files}
        applicantId={userId}
        agentDeleted={agentDeleted}
        onUploadFile={uploadFile}
        onCreateOrUpdate={handlePutAgent}
        onDelete={handleConfirmationDialog}
        onSelectAgent={handleSelectAgent}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          opacity: Object.keys(selectedAgent).length === 0 ? 0.5 : 1,
          pointerEvents:
            Object.keys(selectedAgent).length === 0 ? "none" : "auto",
          position: "relative",
          filter:
            Object.keys(selectedAgent).length === 0
              ? "grayscale(100%)"
              : "none",
        }}
      >
        <ChatWindow
          messages={messages}
          isDisabled={Object.keys(selectedAgent).length === 0}
          dataMessages={dataMessages}
        />
        <ChatBox
          files={files}
          isAdmin={true}
          isGroqAgent={isGroqAgent}
          showSpinner={showSpinner}
          currentMessage={newMessage}
          uploadedStatus={uploadedStatus}
          isUploadingFile={isUploadingFile}
          onUploadFile={uploadFile}
          onUpdateMessages={(message) => setNewMessage(message)}
          onSendMessage={handleSendMessage}
          clearMessages={handleClearMessages}
          isDisabled={Object.keys(selectedAgent).length === 0}
        />
        {Object.keys(selectedAgent).length === 0 && (
          <Box
            sx={{
              p: 2,
              top: "50%",
              left: "50%",
              boxShadow: 3,
              display: "flex",
              borderRadius: "8px",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(-50%, -50%)",
            }}
            zIndex={10}
          >
            No agent has been selected or created
          </Box>
        )}
      </Box>
      <NotificationDialog
        open={openNotification}
        onClose={handleCloseNotification}
        title={notificationContent.title}
        message={notificationContent.message}
        type={notificationContent.type}
      />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleDeleteAgent}
        onReject={handleRejectConfirmation}
        message="Are you sure you want to delete this agent? This action cannot be undone."
      />
    </Box>
  );
};

export default Playground;
