import React, { useState } from "react";
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIELD_HEIGHTS } from "../utils/constants";

const PromptTags = ({ tags, onAddTag, onDeleteTag }) => {
  const [newTag, setNewTag] = useState("");
  const [tagFocused, setTagFocused] = useState(false);

  const handleAddTag = () => {
    if (newTag.trim()) {
      onAddTag(newTag.trim());
      setNewTag("");
    }
  };

  return (
    <>
      <Typography mb={-1} variant="body1" fontWeight="bold">
        Tags
      </Typography>
      <Box
        display="flex"
        gap={1}
        flexWrap="wrap"
        role="group"
        aria-label="Prompt Tags"
      >
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            variant="outlined"
            onDelete={() => onDeleteTag(index)}
            sx={{
              color: "white",
              backgroundColor: "#7CC49A",
            }}
            aria-label={`Remove tag ${tag}`}
          />
        ))}
        <TextField
          placeholder={!tagFocused ? "Add tag" : ""}
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value.trim() !== "") {
              handleAddTag(e.target.value);
              setNewTag("");
            }
          }}
          onFocus={() => setTagFocused(true)}
          onBlur={() => setTagFocused(false)}
          aria-label="Add new tag"
          sx={{
            "& .MuiInputBase-root": {
              width: "60%",
              height: FIELD_HEIGHTS.DEFAULT,
              fontSize: "12px",
              borderRadius: "30px",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          InputProps={{
            startAdornment: !tagFocused && (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faPlus} style={{ fontSize: "16px" }} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              marginTop: "-8px",
            },
          }}
        />
      </Box>
    </>
  );
};

export default PromptTags;
