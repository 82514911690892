import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import DataFetcher from "../../utils/DataFetcher";
import ChatBox from "../../components/ChatBox/ChatBox";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import { getInternshipProgramConversation } from "../../graphql/queries";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

const InternshipAssistant = ({ isDarkMode }) => {
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [updateKey, setUpdateKey] = useState(Date.now());
  const [uploadedStatus, setUploadedStatus] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  useEffect(() => {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;

      Storage.configure({
        region: REGION,
        bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const initialize = async () => {
      const user = await dataFetcher.fetchUserId();
      setUserId(user.username);
      if (user) {
        const applicantId = user.username;
        const files = await dataFetcher.fetchFiles(applicantId);
        setFiles(files);
      }
    };

    initialize();
  }, [updateKey]);

  const handleClearMessages = () => {
    setMessages([]);
    localStorage.removeItem("chatMessages");
  };

  const uploadFile = useCallback(
    async (event) => {
      setIsUploadingFile(true);
      const uploadedFiles = await handleFileUpload(event.target.files, userId);
      if (!_.isEmpty(uploadedFiles)) {
        await dataFetcher.saveFilesToDatabase(uploadedFiles, userId);
        if (uploadedFiles.length) {
          setUploadedStatus("success");
        }
      }
      setUpdateKey(Date.now());
      setIsUploadingFile(false);
    },
    [userId]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
          });
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      return uploadedFiles;
    } catch (error) {
      setUploadedStatus("error");
      throw new Error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    setNewMessage("");

    const newSentMessage = { message: newMessage, type: "sent" };
    const newLoadingMessage = {
      message: "",
      type: "received",
      isLoading: true,
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      newSentMessage,
      newLoadingMessage,
    ]);

    setShowSpinner(true);

    const params = {
      customer_id: "cai3p0",
      applicant_id: userId,
      message: newMessage,
    };

    const handleApiError = (messages) => {
      return messages.map((msg) =>
        msg.isLoading
          ? {
              ...msg,
              message: "Oops! Something went wrong. Please try again.",
              isLoading: false,
            }
          : msg
      );
    };

    try {
      let openAiAnswer = null;

      let local_msgs = [];
      local_msgs = _.union(messages, []);

      let resultConversationOpenAi = null;
      try {
        resultConversationOpenAi = await API.graphql({
          query: getInternshipProgramConversation,
          variables: params,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (!resultConversationOpenAi.errors?.length) {
          const apiResponse =
            resultConversationOpenAi.data.getInternshipProgramConversation;

          console.log("apiResponse: ", apiResponse);
          const parseResponseBody = (responseBody) => JSON.parse(responseBody);

          local_msgs = _.union(local_msgs, [newSentMessage, newLoadingMessage]);
          let tmpMsg = "";
          let initialMessages = [...local_msgs];
          let isStreaming = false;
          setMessages(local_msgs);

          let assistantResponseSub = API.graphql({
            ...graphqlOperation(onChatAssistantResponse, {
              id: apiResponse.id,
            }),
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).subscribe({
            next: ({ provider, value }) => {
              const subApiResponse = value.data.onChatAssistantResponse;
              console.log("subApiResponse: ", subApiResponse);
              const body = parseResponseBody(subApiResponse.body);
              if (body.error) {
                const newMessages = initialMessages.map((msg) =>
                  msg.isLoading
                    ? {
                        ...msg,
                        message: body.error,
                        isLoading: false,
                      }
                    : msg
                );
                setMessages(newMessages);
                return;
              }
              openAiAnswer = body.answer;
              setShowSpinner(false);

              if (subApiResponse.status === "done") {
                if (!isStreaming) {
                  const newMessages = [
                    ...initialMessages,
                    { message: openAiAnswer, type: "received" },
                  ];
                  setMessages(newMessages);
                  initialMessages = [...newMessages];
                }
                assistantResponseSub.unsubscribe();
                tmpMsg = "";
                initialMessages = [...messages];
                isStreaming = false;
                setShowSpinner(false);
              } else {
                isStreaming = true;
                tmpMsg += openAiAnswer;
                const newMessages = initialMessages.map((msg) =>
                  msg.isLoading
                    ? {
                        ...msg,
                        message: tmpMsg,
                        isLoading: false,
                      }
                    : msg
                );
                setMessages(newMessages);
              }
            },
            error: (error) => {
              console.warn(error);
              setShowSpinner(false);
              setMessages(handleApiError(local_msgs));
            },
          });
        }
      } catch (err) {
        console.log(err);
        setShowSpinner(false);
        setMessages(handleApiError(local_msgs));
      }
    } catch (error) {
      console.error(error);

      if (
        error.errors &&
        error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
      ) {
        setMessages((prevMessages) => handleApiError(prevMessages));
      }
    }
  };

  return (
    <>
      <ChatWindow isDarkMode={isDarkMode} messages={messages} />
      <ChatBox
        isDarkMode={isDarkMode}
        files={files}
        isAdmin={false}
        isGroqAgent={false}
        showSpinner={showSpinner}
        currentMessage={newMessage}
        uploadedStatus={uploadedStatus}
        isUploadingFile={isUploadingFile}
        onUploadFile={uploadFile}
        onUpdateMessages={(message) => setNewMessage(message)}
        onSendMessage={handleSendMessage}
        clearMessages={handleClearMessages}
      />
    </>
  );
};

export default InternshipAssistant;
