import { API, Auth } from "aws-amplify";
import { flow, makeObservable, observable } from "mobx";
import { getAgent, getFiles } from "../../graphql/queries";

class AuthStore {
  isLoading = true;
  isError = false;
  error = "";
  isSuperAdmin = false;
  isUser = false;
  userId = "";
  files = [];
  agents = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isError: observable,
      error: observable,
      isSuperAdmin: observable,
      isUser: observable,
      userId: observable,
      files: observable,
      agents: observable,
      getGlobalInformation: flow,
      fetchFiles: flow,
      fetchAgents: flow,
    });
  }

  clear() {
    this.isLoading = true;
    this.isError = false;
    this.isSuperAdmin = false;
    this.isUser = false;
    this.userId = "";
    this.files = [];
    this.agents = [];
  }

  *getGlobalInformation() {
    try {
      const userInfo = yield Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const groups =
        userInfo.signInUserSession.accessToken.payload["cognito:groups"];

      this.isSuperAdmin = groups.includes("superadmin");
      this.isUser = groups.includes("user");
      // this.userId = userInfo.attributes.sub ?? "";
      this.userId = userInfo.username;
      this.isLoading = false;
    } catch (e) {
      this.clear();
    }
  }

  *fetchFiles() {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getFiles,
        variables: { applicant_id: this.userId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const fetchedFiles = JSON.parse(response.data?.getFiles?.body);
      this.files = fetchedFiles;
    } catch (error) {
      this.isError = true;
    }
  }

  *fetchAgents() {
    const params = {
      customer_id: "cai3p0",
      domain: this.userId,
      provider: "",
      group: false,
    };
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const fetchAgents = JSON.parse(response.data.getAgent.body);
      this.agents = fetchAgents;
      return fetchAgents;
    } catch (error) {
      this.isError = true;
      return [];
    }
  }
}

export default AuthStore;
