export const FETCH_RETRIES = 2;

export const ERROR_MESSAGES = {
  PROMPT_NAME_REQUIRED: "Prompt Name is required.",
  INSTRUCTIONS_REQUIRED: "Instructions are required.",
  VARIABLES_INCOMPLETE:
    "All input variables must have both a name and a value.",
  SAVE_ERROR: "An error occurred while saving the prompt. Please try again.",
  DELETE_ERROR:
    "An error occurred while deleting the prompt. Please try again.",
};

export const DEFAULT_PROMPT_STATE = {
  name: "",
  description: "",
  instructions: "",
  tags: [],
  inputVariables: [{ name: "", value: "" }],
};

export const FIELD_HEIGHTS = {
  DEFAULT: "36px",
  MULTILINE: "100px",
};

export const COLORS = {
  PRIMARY: "#015d86",
  PRIMARY_HOVER: "#014a6d",
  ERROR: "#D2122E",
};
