import { makeObservable, observable } from "mobx";
import AuthStore from "./auth/AuthStore";

class Store {
  authStore = new AuthStore();

  constructor() {
    makeObservable(this, {
      authStore: observable,
    });
  }
}

const DefaultStore = new Store();
export default DefaultStore;
