import React, { useEffect, useState } from "react";
import { helix } from "ldrs";
import { Box, Typography } from "@mui/material";

helix.register();

const LoadingScreen = () => {
  const [responseDots, setResponseDots] = useState("");

  useEffect(() => {
    helix.register();
    const interval = setInterval(() => {
      setResponseDots((prev) => {
        return prev.length < 3 ? prev + "." : "";
      });
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <l-helix size="245" speed="2.5" color="blue"></l-helix>
      <Box sx={{ display: "flex", width: "121%" }}>
        <Typography sx={{ marginTop: "20px", fontFamily: "Segoe UI" }}>
          {`We are setting up all internship services${responseDots}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
