import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  onReject,
  title,
  message,
  secondaryMessage,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <Box
        sx={{
          padding: 1,
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <DialogTitle>{title || "Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <DialogContentText>{secondaryMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject} color="inherit">
            No
          </Button>
          <Button onClick={onConfirm} color="error" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
