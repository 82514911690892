import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteCustomer } from "../../graphql/queries";
import { faBan, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DataTable = ({ type, data, columns, onEdit, onDelete }) => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isDeleteButtonDisabled = deleteName !== currentRow?.name;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClick = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteName("");
    setDeleteDialogOpen(false);
  };

  const handleDeleteNameChange = (e) => {
    setDeleteName(e.target.value);
  };

  const handleEdit = () => {
    onEdit(currentRow);
    handleMenuClose();
  };

  const handleDelete = () => {
    handleOpenDeleteDialog();
  };

  const handleConfirmDelete = () => {
    onDelete(currentRow.id);
    handleCloseDeleteDialog();
    handleMenuClose();
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < data.length
                  }
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  key={row.id}
                  selected={isItemSelected}
                  onClick={(event) => handleRowClick(event, row.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar>{row.name[0]}</Avatar>
                      <Box ml={2}>
                        <Typography variant="body1">{row.name}</Typography>
                        {row.guest && (
                          <Typography variant="caption" color="textSecondary">
                            Guest
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleMenuClick(event, row)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      open={isMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleMenuClose}
                      sx={{
                        boxShadow: 1,
                        borderRadius: "12px",
                        "& .MuiPaper-root": {
                          borderRadius: "6px",
                          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      <MenuItem onClick={handleEdit}>
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ marginRight: "12px" }}
                        />
                        <ListItemText primary="Edit" />
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <FontAwesomeIcon
                          icon={faBan}
                          style={{ marginRight: "12px" }}
                        />
                        <ListItemText primary="Delete" />
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: { padding: "12px" },
        }}
      >
        <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }}>
          {`Are you sure you want to delete this ${type}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "14px" }}>
            {`Please type the full name of the ${type} to confirm the deletion:`}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            value={deleteName}
            onChange={handleDeleteNameChange}
            helperText={`Type the full name: ${currentRow?.name}`}
            sx={{
              marginTop: "8px",
              "& .MuiInputBase-root": {
                height: "36px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& .MuiFormHelperText-root": {
                marginTop: "6px",
                marginLeft: "0px",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              color: "black",
              backgroundColor: "transparent",
              "&:hover": { color: "black", backgroundColor: "grey" },
            }}
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleConfirmDelete}
            disabled={isDeleteButtonDisabled}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DataTable;
