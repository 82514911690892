export const COLUMN_CONTACTS = [
  { id: "name", label: "Contact" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
];

export const CONTACT_DATA_VALUES = {
  customer: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  id: "",
  insert_date: "",
  last_modified: "",
  active: false,
};

export const CONTACT_FIELDS = [
  {
    name: "firstname",
    label: "First Name",
    type: "text",
    validate: (value) => (value.trim() ? "" : "First Name is required"),
  },
  {
    name: "lastname",
    label: "Last Name",
    type: "text",
    validate: (value) => (value.trim() ? "" : "Last name is required"),
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "text",
    validate: (value) => (/^\d{10}$/.test(value) ? "" : "Invalid phone number"),
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    validate: (value) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email address",
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    validate: (value) => (value.trim() ? "" : "Address is required"),
  },
];
