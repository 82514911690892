import { useState } from "react";

const UseNotification = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationContent, setNofiticationContent] = useState({
    title: "",
    message: "",
    type: "",
  });

  const handleOpenNotification = (title, message, type) => {
    setNofiticationContent({ title, message, type });
    setOpenNotification(true);
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  return {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  };
};

export default UseNotification;
