import React from "react";
import { TextField, Typography } from "@mui/material";
import { FIELD_HEIGHTS } from "../utils/constants";

const PromptInstructionsField = ({ instructions, error, onChange }) => (
  <>
    <Typography mb={-1} variant="body1" fontWeight="bold">
      Instructions <span style={{ color: "red" }}>*</span>
    </Typography>
    <TextField
      required
      id="prompt-instructions"
      placeholder="Instructions"
      value={instructions}
      onChange={(e) => onChange(e, "instructions")}
      error={!!error}
      helperText={error}
      multiline
      rows={4}
      fullWidth
      variant="outlined"
      InputProps={{
        sx: { fontSize: "14px" },
      }}
      sx={{
        "& .MuiInputBase-root": { minHeight: FIELD_HEIGHTS.MULTILINE },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": { borderColor: "black" },
        },
      }}
    />
  </>
);

export default PromptInstructionsField;
