import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ArrowRight, CloudUpload } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHammer } from "@fortawesome/free-solid-svg-icons";
import DataFetcher from "../../utils/DataFetcher";
import { FETCH_RETRIES } from "../PromptDashboard/utils/constants";

const AssistantDetails = ({
  selectedPrompt,
  files,
  applicantId,
  agentDeleted,
  onUploadFile,
  onCreateOrUpdate,
  onDelete,
  onSelectAgent,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dataFetcher = DataFetcher();
  const [agents, setAgents] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [assistantName, setAssistantName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({});
  const [agentsLoaded, setAgentsLoaded] = useState(false);
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [displayFields, setDisplayFields] = useState(false);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [openAgentsList, setOpenAgentsList] = useState(false);
  const [openPromptsList, setOpenPromptsList] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(selectedPrompt ?? null);
  const [instructions, setInstructions] = useState(
    selectedPrompt ? selectedPrompt.instructions : ""
  );

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const clearFields = async () => {
      if (agentDeleted === "Yes") {
        await fetchAgents(applicantId);
        setAssistantName("");
        setInstructions("");
        setSelectedAgent({});
      }
      setLoadingAction(false);
    };

    clearFields();
  }, [applicantId, agentDeleted]);

  const fetchAgents = async (applicantId) => {
    setLoadingAgents(true);
    try {
      const fetchedAgents = await dataFetcher.fetchAgents(applicantId);
      setAgents(fetchedAgents);
      setLoadingAgents(false);
      setAgentsLoaded(true);
    } catch (error) {
      console.log("Error fetching agents:", error);
      setLoadingAgents(false);
      throw new Error("Error fetching agents:", error);
    }
  };

  const fetchPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const fetchedPrompts = await dataFetcher.fetchPrompts(FETCH_RETRIES);
      setPrompts(fetchedPrompts);
      setLoadingPrompts(false);
      setPromptsLoaded(true);
    } catch (error) {
      console.log("Error fetching prompts:", error);
      setLoadingPrompts(false);
      throw new Error("Error fetching prompts:", error);
    }
  };

  const onDrop = (acceptedFiles) => {
    setErrorMessage("");
    onUploadFile(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const unsupportedFile = rejectedFiles[0];
    if (unsupportedFile) {
      setErrorMessage(
        `File ${unsupportedFile.file.name} is not a supported format. Only DOCX or PDF files.`
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  const handleSelectAgent = (agent) => {
    onSelectAgent(agent);
    setOpenAgentsList(false);
    setSelectedAgent(agent);
    setAssistantName(agent.name || "");
    setInstructions(currentPrompt?.instructions || agent.prompt || "");
  };

  const handleNewAgent = () => {
    setDisplayFields(true);
    setAssistantName("");
    setInstructions("");
    setSelectedAgent({});
    setCurrentPrompt(null);
  };

  const handleCreateOrUpdate = async () => {
    setLoadingAction(true);
    const data = {
      instructions: instructions,
      name: assistantName,
      selectedAgentId: selectedAgent.id ?? "",
    };
    await onCreateOrUpdate(data);
    await fetchAgents(applicantId);
    setLoadingAction(false);
  };

  const handleDelete = async () => {
    setLoadingAction(true);
    await onDelete();
  };

  const handleSelectPrompt = (prompt) => {
    setCurrentPrompt(prompt);
    setOpenPromptsList(false);
    setInstructions(prompt?.instructions);
  };

  return (
    <Box
      gap={2}
      width={isMobileOrTablet ? "100%" : "20%"}
      padding={2}
      display="flex"
      borderRight={1}
      borderColor="divider"
      flexDirection="column"
      sx={{ backgroundColor: "#f9f9f9" }}
    >
      <FormControl fullWidth sx={{ marginTop: "6px" }}>
        <InputLabel sx={{ fontSize: "13px", margin: "-5px", fontWeight: 700 }}>
          Select Assistant
        </InputLabel>
        <Select
          displayEmpty
          open={openAgentsList}
          value={selectedAgent?.name || ""}
          onOpen={() => {
            if (!agentsLoaded) {
              fetchAgents(applicantId);
            }
            setOpenAgentsList(true);
          }}
          onClose={() => setOpenAgentsList(false)}
          renderValue={(selected) => {
            return selected || "";
          }}
          sx={{
            height: "65%",
            "& .MuiSelect-select": {
              fontSize: "13px",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: "auto",
                minWidth: "100%",
                maxWidth: "100%",
                maxHeight: "200px",
              },
            },
          }}
        >
          {loadingAgents ? (
            <MenuItem disabled>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <CircularProgress size={24} />
              </Box>
            </MenuItem>
          ) : agents?.length > 0 ? (
            <Scrollbars style={{ height: "180px", width: "100%" }}>
              {agents.map((agent) => (
                <MenuItem
                  key={agent.id}
                  value={agent.name}
                  sx={{
                    fontSize: "12px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => handleSelectAgent(agent)}
                >
                  {agent.name}
                </MenuItem>
              ))}
            </Scrollbars>
          ) : (
            <MenuItem disabled>No assistants found</MenuItem>
          )}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleNewAgent}
        sx={{
          color: "white",
          marginTop: "-10px",
          alignSelf: "center",
          marginBottom: "-2px",
          textTransform: "none",
          backgroundColor: "#015d86",
          "&:hover": { backgroundColor: "#014a6d" },
          "& .MuiButton-startIcon>*:nth-of-type(1)": {
            fontSize: "14px !important",
          },
        }}
        startIcon={<Box>&#128640;</Box>}
      >
        <Typography sx={{ fontSize: "13px" }}>New Agent</Typography>
      </Button>
      {(selectedPrompt !== null ||
        displayFields ||
        Object.keys(selectedAgent).length > 0) && (
        <>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              fontSize: "14px",
              marginTop: "-10px",
              marginBottom: "-12px",
            }}
          >
            Name:
          </Typography>
          <TextField
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            fullWidth
            InputProps={{
              sx: {
                height: "60%",
                fontSize: "14px",
              },
            }}
            sx={{
              marginBottom: "-10px",
            }}
          />
          <FormControl fullWidth sx={{ marginTop: "16px" }}>
            <InputLabel
              sx={{
                margin: "-5px",
                fontWeight: 700,
                fontSize: "13px",
                marginTop: "-18px",
              }}
            >
              Select Prompt
            </InputLabel>
            <Select
              displayEmpty
              open={openPromptsList}
              value={currentPrompt?.name || ""}
              onOpen={() => {
                if (!promptsLoaded) {
                  fetchPrompts();
                }
                setOpenPromptsList(true);
              }}
              onClose={() => setOpenPromptsList(false)}
              renderValue={(selected) => {
                return selected || "";
              }}
              sx={{
                height: "65%",
                marginTop: "-10px",
                "& .MuiSelect-select": {
                  fontSize: "13px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: "auto",
                    minWidth: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                    maxHeight: "155px",
                  },
                },
              }}
            >
              {loadingPrompts ? (
                <MenuItem disabled>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                  >
                    <CircularProgress size={24} />
                  </Box>
                </MenuItem>
              ) : prompts?.length > 0 ? (
                <Scrollbars style={{ height: "140px", width: "100%" }}>
                  {prompts.map((prompt) => (
                    <MenuItem
                      key={prompt.name}
                      value={prompt.name}
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => handleSelectPrompt(prompt)}
                    >
                      {prompt.name}
                    </MenuItem>
                  ))}
                </Scrollbars>
              ) : (
                <MenuItem disabled>No prompts available</MenuItem>
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              display: "flex",
              fontSize: "14px",
              marginTop: "-14px",
              marginBottom: "-12px",
              justifyContent: "space-between",
            }}
          >
            Instructions:
            {currentPrompt && (
              <Button
                variant="contained"
                color="success"
                startIcon={<FontAwesomeIcon icon={faHammer} />}
                sx={{
                  height: "30px",
                  minWidth: "30px",
                  textTransform: "none",
                }}
                onClick={() => {
                  navigate("/prompts", { state: { currentPrompt } });
                }}
              >
                Customize
              </Button>
            )}
          </Typography>
          <TextField
            rows={4}
            fullWidth
            multiline
            value={instructions}
            InputProps={{
              sx: { fontSize: "14px", marginTop: "2px" },
            }}
            onChange={(e) => {
              setCurrentPrompt(null);
              setInstructions(e.target.value);
            }}
          />
          <Box
            {...getRootProps()}
            sx={{
              padding: 2,
              width: "100%",
              height: "100px",
              cursor: "pointer",
              textAlign: "center",
              borderRadius: "4px",
              marginTop: "15px",
              border: "2px dashed #ccc",
              backgroundColor: "#fafafa",
            }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CloudUpload />
            </IconButton>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body1">Drop the files here...</Typography>
            ) : (
              <Typography variant="body1" sx={{ fontSize: "13px" }}>
                Click or drag file to this area to upload
              </Typography>
            )}
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <Paper variant="outlined" sx={{ marginTop: 2 }}>
            <Typography
              padding={1}
              sx={{
                color: "white",
                fontSize: "14px",
                backgroundColor: "#015d86",
              }}
            >
              Document List
            </Typography>
            <Box sx={{ height: "150px" }}>
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                <List sx={{ overflowY: "auto" }}>
                  {files?.length > 0 &&
                    files.map((file, index) => (
                      <ListItem key={index}>
                        <ListItemIcon
                          sx={{ marginLeft: "-5px", marginRight: "-24px" }}
                        >
                          <ArrowRight />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: "block",
                                overflow: "hidden",
                                fontFamily: "13px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {file.file_name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              </Scrollbars>
            </Box>
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: isMobileOrTablet ? "30px" : "50px",
              marginBottom: isMobileOrTablet ? "5px" : "50px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCreateOrUpdate}
              disabled={loadingAction}
              sx={{
                color: "white",
                marginTop: "-30px",
                alignSelf: "center",
                textTransform: "none",
                backgroundColor: "#015d86",
                "&:hover": { backgroundColor: "#014a6d" },
              }}
              startIcon={
                loadingAction ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ fontSize: "15px" }}
                  />
                )
              }
            >
              <Typography sx={{ fontSize: "13px" }}>
                {loadingAction
                  ? "Saving..."
                  : Object.keys(selectedAgent).length > 0
                  ? "Save changes"
                  : "Create agent"}
              </Typography>
            </Button>
            {Object.keys(selectedAgent).length > 0 && (
              <Button
                variant="contained"
                onClick={handleDelete}
                disabled={loadingAction}
                sx={{
                  color: "white",
                  marginTop: "-30px",
                  marginLeft: "40px",
                  alignSelf: "center",
                  textTransform: "none",
                  backgroundColor: "#D2122E",
                  "&:hover": { backgroundColor: "#b20000" },
                }}
              >
                <Typography sx={{ fontSize: "13px" }}>Delete</Typography>
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssistantDetails;
