import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowRight,
  AttachFile,
  Close,
  PermMedia,
  Send,
} from "@mui/icons-material";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStore from "../../hooks/useStore";
import SuggestedQuestionsButton from "./SuggestedQuestionsButton";

const switchStyles = {
  "& .MuiSwitch-switchBase": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
};

const formControlLabelStyles = {
  width: "150px",
  marginLeft: "2px",
  "& .MuiTypography-root": {
    fontSize: "12px",
    marginTop: "3px",
  },
};

const ChatBox = ({
  isDarkMode,
  files,
  isAdmin,
  isGroqAgent,
  showSpinner,
  currentMessage,
  uploadedStatus,
  isUploadingFile,
  onUploadFile,
  onUpdateMessages,
  onSendMessage,
  clearMessages,
  handleModelType,
}) => {
  const { authStore } = useStore();
  const textFieldRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const [model, setModel] = useState("INSTANT");
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [isFileListVisible, setFileListVisible] = useState(false);

  const fontColorStyle = isDarkMode ? "white" : "black";
  const backgroundColorStyle = isDarkMode ? "#272727" : "#fff";

  useEffect(() => {
    if (authStore.isLoading) {
      authStore.getGlobalInformation();
    }
  }, [authStore]);

  useEffect(() => {
    if (uploadedStatus) {
      setAlertVisible(true);
      setOpacity(1);
      const fadeOutTimer = setTimeout(() => {
        const fadeEffect = setInterval(() => {
          setOpacity((prev) => {
            if (prev > 0) {
              return prev - 0.05;
            } else {
              clearInterval(fadeEffect);
              setAlertVisible(false);
              return 0;
            }
          });
        }, 200);
      }, 4000);
      return () => {
        clearTimeout(fadeOutTimer);
      };
    }
  }, [uploadedStatus]);

  useEffect(() => {
    if (!showSpinner) {
      textFieldRef.current.focus();
    }
  }, [showSpinner]);

  useEffect(() => {
    if (selectedQuestion && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [selectedQuestion]);

  const toggleFileList = useCallback(() => {
    setFileListVisible((prev) => !prev);
  }, []);

  const handleQuestionClick = useCallback(
    (item) => {
      onUpdateMessages(item.text);
      setSelectedQuestion(item.text);
    },
    [onUpdateMessages]
  );

  const handleModelChange = (event) => {
    const newModel = event.target.checked ? "VERSATILE" : "INSTANT";
    setModel(newModel);
    handleModelType(newModel);
  };

  const handleSendMessage = useCallback(() => {
    onSendMessage();
  }, [onSendMessage]);

  const renderedFiles = useMemo(
    () =>
      files.map((file, index) => (
        <ListItem key={index} sx={{ marginBottom: "-14px", marginTop: "-5px" }}>
          <ListItemIcon sx={{ marginLeft: "-17px", marginRight: "-15px" }}>
            <ArrowRight />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                sx={{
                  display: "block",
                  overflow: "hidden",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {file.file_name}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`Description: ${file.file_description}`}
              </Typography>
            }
          />
        </ListItem>
      )),
    [files]
  );

  return (
    <Box sx={{ height: "22%", backgroundColor: backgroundColorStyle }}>
      <Divider
        sx={{
          backgroundColor: isDarkMode ? "white" : "#BDC3C760",
        }}
      />
      {isUploadingFile && <LinearProgress />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: "4px",
          alignItems: "center",
          marginBottom: "-9px",
          justifyContent: "space-between",
        }}
      >
        <Dialog
          fullWidth
          maxWidth="sm"
          open={isFileListVisible}
          onClose={toggleFileList}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Your Assistant knowledge base
            </Typography>
            <IconButton
              aria-label="close"
              onClick={toggleFileList}
              sx={{
                top: 8,
                right: 8,
                position: "absolute",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <List>{renderedFiles}</List>
          </DialogContent>
        </Dialog>
      </Box>
      <Box
        sx={{
          margin: "25px",
          display: "flex",
          padding: "12px",
          marginBottom: "20px",
          flexDirection: "column",
          border: "1px solid #ccc",
          backgroundColor: backgroundColorStyle,
        }}
      >
        <TextField
          autoFocus
          multiline
          fullWidth
          maxRows={10}
          variant="outlined"
          inputRef={textFieldRef}
          placeholder="Type your message..."
          value={currentMessage}
          onChange={(e) => onUpdateMessages(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !showSpinner) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            sx: {
              color: fontColorStyle,
              fontSize: "14px",
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent !important",
              },
              "&.Mui-disabled": {
                "& fieldset": {
                  borderColor: "transparent !important",
                },
              },
            },
            style: {
              resize: "none",
              borderRadius: "25px",
              borderColor: "transparent !important",
              backgroundColor: backgroundColorStyle,
            },
          }}
          disabled={showSpinner}
        />
        <Box
          sx={{
            display: "flex",
            marginTop: "25px",
            marginLeft: "15px",
          }}
        >
          <Tooltip title="Clear Chat" arrow placement="top">
            <Button
              variant="contained"
              component="label"
              sx={{
                width: "0px",
                height: "31px",
                display: "flex",
                minWidth: "0px",
                marginTop: "3px",
                borderRadius: "20px",
                backgroundColor: "#015d86",
                "&:hover": {
                  backgroundColor: "#1a749c",
                },
              }}
              onClick={clearMessages}
            >
              <FontAwesomeIcon icon={faBroom} />
            </Button>
          </Tooltip>
          {isAdmin && isGroqAgent && (
            <FormControlLabel
              control={
                <Tooltip title="Change model" arrow placement="top">
                  <Switch
                    sx={switchStyles}
                    onChange={handleModelChange}
                    checked={model === "VERSATILE"}
                  />
                </Tooltip>
              }
              label={
                <Typography
                  fontWeight="bold"
                  variant="subtitle1"
                  sx={{ color: "#015d86" }}
                >
                  {model}
                </Typography>
              }
              sx={formControlLabelStyles}
            />
          )}
          {!isAdmin && (
            <Tooltip title="Add files" arrow placement="top">
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "0px",
                  height: "31px",
                  display: "flex",
                  minWidth: "0px",
                  marginTop: "3px",
                  marginLeft: "10px",
                  borderRadius: "20px",
                  backgroundColor: "#015d86",
                  "&:hover": {
                    backgroundColor: "#1a749c",
                  },
                }}
              >
                <AttachFile sx={{ width: "20px", color: "white" }} />
                <input type="file" hidden onChange={onUploadFile} />
              </Button>
            </Tooltip>
          )}
          {!isFileListVisible && !isAdmin && (
            <Tooltip title="Show files" arrow placement="top">
              <Button
                variant="contained"
                color="secondary"
                onClick={toggleFileList}
                sx={{
                  width: "0px",
                  height: "31px",
                  display: "flex",
                  minWidth: "0px",
                  marginTop: "3px",
                  marginLeft: "10px",
                  borderRadius: "20px",
                }}
              >
                <PermMedia sx={{ width: "20px" }} />
              </Button>
            </Tooltip>
          )}
          {alertVisible && (
            <Box
              sx={{
                bottom: "4px",
                height: "30px",
                marginLeft: "13px",
                position: "relative",
              }}
            >
              {uploadedStatus && (
                <Alert
                  severity={uploadedStatus === "success" ? "success" : "error"}
                  sx={{
                    borderRadius: "10px",
                    transition: "opacity 1s ease-out",
                    opacity: opacity,
                  }}
                >
                  {uploadedStatus === "success"
                    ? "File uploaded successfully"
                    : "Error during uploading process"}
                </Alert>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <SuggestedQuestionsButton
              handleSelectQuestion={handleQuestionClick}
            />
            <Button
              disabled={showSpinner}
              onClick={handleSendMessage}
              sx={{
                minWidth: "20px",
                marginLeft: "10px",
                borderRadius: "25px",
              }}
            >
              {showSpinner ? (
                <CircularProgress size={24} />
              ) : (
                <Send sx={{ color: "#015d86" }} />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBox;
