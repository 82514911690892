import React, { useEffect, useState, useCallback } from "react";
import { API } from "aws-amplify";
import { SyncLoader } from "react-spinners";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import PromptList from "./PromptList";
import PromptBuilder from "./PromptBuilder";
import DataFetcher from "../../utils/DataFetcher";
import UseNotification from "../../utils/UseNotification";
import { deletePrompt, putPrompt } from "../../graphql/queries";
import { COLORS, DEFAULT_PROMPT_STATE, FETCH_RETRIES } from "./utils/constants";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";

const PromptDashboard = () => {
  const theme = useTheme();
  const dataFetcher = DataFetcher();
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [validPrompts, setValidPrompts] = useState([]);
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  } = UseNotification();

  const loadPrompts = useCallback(async () => {
    setIsLoading(true);
    setLoadError(null);
    try {
      const fetchedPrompts = await dataFetcher.fetchPrompts(FETCH_RETRIES);
      if (fetchedPrompts.errors) {
        setLoadError(fetchedPrompts.errors[0].message);
        throw new Error(fetchedPrompts.error);
      }
      setValidPrompts(fetchedPrompts);
      setPromptsLoaded(true);
    } catch (error) {
      console.error("Error loading prompts: ", error);
      setLoadError(error.errors[0].message);
    } finally {
      setIsLoading(false);
    }
  }, [dataFetcher]);

  useEffect(() => {
    if (!promptsLoaded) {
      loadPrompts();
    }
  }, [promptsLoaded, loadPrompts]);

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt || DEFAULT_PROMPT_STATE);
  };

  const handleErrorNotification = (message) => {
    handleOpenNotification("Error", message, "error");
  };

  const handlePutPrompt = async (promptData) => {
    try {
      const response = await API.graphql({
        query: putPrompt,
        variables: promptData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      const responseBody = JSON.parse(response.data.putPrompt.body);

      if (!responseBody.error) {
        setPromptsLoaded(false);
        const typeMessage = selectedPrompt ? "updated" : "created";

        handleOpenNotification(
          "Success",
          `Prompt successfully ${typeMessage}.`,
          "success"
        );
      } else {
        handleErrorNotification(
          "An error occurred in the process, verify and try again."
        );
      }
    } catch (error) {
      handleErrorNotification(
        "An error occurred in the process, verify and try again."
      );
      console.error("Error during the process:", error);
    }
  };

  const handleDeletePrompt = async (name) => {
    const params = {
      name: name,
    };
    try {
      const response = await API.graphql({
        query: deletePrompt,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      const responseBody = JSON.parse(response.data.deletePrompt.body);
      if (responseBody.success) {
        setPromptsLoaded(false);
        setSelectedPrompt(null);
        handleOpenNotification(
          "Success",
          "Prompt successfully deleted.",
          "success"
        );
      } else {
        handleErrorNotification("An error occurred while deleting the prompt.");
      }
    } catch (error) {
      handleErrorNotification("An error occurred while deleting the prompt.");
      console.error("Error deleting prompts:", error);
    }
  };

  useEffect(() => {
    if (openNotification) {
      const timer = setTimeout(() => {
        handleCloseNotification();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [openNotification, handleCloseNotification]);

  const renderPromptList = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <SyncLoader />
        </Box>
      );
    }

    if (loadError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="body1" color="error" gutterBottom>
            {loadError}
          </Typography>
          <Button
            variant="contained"
            onClick={loadPrompts}
            sx={{
              mt: "10px",
              backgroundColor: COLORS.PRIMARY,
              "&:hover": { backgroundColor: COLORS.PRIMARY_HOVER },
            }}
          >
            Retry
          </Button>
        </Box>
      );
    }

    return (
      <PromptList prompts={validPrompts} onPromptSelect={handlePromptSelect} />
    );
  };

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={isMobileOrTablet ? "column" : "row"}
    >
      <Box
        gap={2}
        width={isMobileOrTablet ? "100%" : "20%"}
        padding="10px"
        display="flex"
        borderRight={1}
        borderColor="divider"
        flexDirection="column"
        sx={{ backgroundColor: "#f9f9f9" }}
      >
        {renderPromptList()}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          position: "relative",
        }}
      >
        <PromptBuilder
          selectedPrompt={selectedPrompt}
          onSave={handlePutPrompt}
          onDelete={handleDeletePrompt}
        />
      </Box>
      <NotificationDialog
        open={openNotification}
        onClose={handleCloseNotification}
        title={notificationContent.title}
        message={notificationContent.message}
        type={notificationContent.type}
      />
    </Box>
  );
};

export default PromptDashboard;
