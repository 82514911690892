import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from "@mui/material/List";
import {ListSubheader} from "@mui/material";
// import {FixedSizeList, VariableSizeList} from 'react-window';
// import {mainNavbarItems} from "../../navbar/consts/navBarItems";


const BasicList = ({items, onSelect, title}) => {
  // Item size is based on the length of the data.
  // const getItemSize = (index) => items[index].length;

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        borderColor: 'primary.main',
        borderRadius: 1
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {title}
        </ListSubheader>
      }
    >
      {items.map((item, index) => (
        <ListItem key={item.id}>
          <ListItemButton
            onClick={(e) => onSelect(e, item)}
          >
            <ListItemText primary={item.primary} secondary={item.secondary ? item.secondary : null}/>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default BasicList