import React, { useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import BackgroundImage from "../../../images/BackgroundImage.jpg";

const MAX_WIDTH = "800px";
const BACKGROUND_COLOR = "rgba(3, 138, 255, 0.6)";

const useResponsivePadding = (minWidth = 499, maxWidth = 2000) => {
  const theme = useTheme();

  return useMemo(() => {
    const calcPadding = (size) => {
      const top =
        160 + (80 - 160) * ((size - minWidth) / (maxWidth - minWidth));
      const right =
        20 + (39 - 20) * ((size - minWidth) / (maxWidth - minWidth));
      const bottom =
        85 + (5 - 85) * ((size - minWidth) / (maxWidth - minWidth));
      const left = 30 + (63 - 30) * ((size - minWidth) / (maxWidth - minWidth));
      return `${top}px ${right}px ${bottom}px ${left}px`;
    };

    return {
      [theme.breakpoints.up(minWidth)]: {
        padding: calcPadding("100vw"),
      },
      [theme.breakpoints.up(maxWidth)]: {
        padding: "80px 39px 5px 63px",
      },
    };
  }, [theme, minWidth, maxWidth]);
};

const StyledTypography = ({ variant, sx, ...props }) => {
  const responsivePadding = useResponsivePadding();

  return (
    <Typography
      variant={variant}
      sx={{
        ...responsivePadding,
        ...sx,
      }}
      {...props}
    />
  );
};

const InfoListItem = ({ title, description }) => {
  return (
    <ListItem sx={{ padding: "0", margin: ".5em 0" }}>
      <ListItemText
        primary={
          <StyledTypography fontWeight="bold" sx={{ marginBottom: ".5em" }}>
            {title}
          </StyledTypography>
        }
        secondary={
          <StyledTypography variant="body2">{description}</StyledTypography>
        }
        sx={{ "& .MuiListItemText-secondary": { color: "white" } }}
      />
    </ListItem>
  );
};

const OnboardingLeftSide = () => {
  const responsivePadding = useResponsivePadding();

  const backgroundImageStyle = useMemo(
    () => ({
      backgroundImage: `url(${BackgroundImage})`,
    }),
    []
  );

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="inherit"
      flexDirection="column"
      justifyContent="initial"
      sx={{
        maxWidth: MAX_WIDTH,
        margin: "0 auto",
        width: "100%",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        minHeight: "100%",
        ...backgroundImageStyle,
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: BACKGROUND_COLOR,
        }}
      >
        <Box
          sx={{
            zIndex: 2,
            color: "#fff",
            width: "100%",
            height: "100%",
            display: "flex",
            textAlign: "left",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
            ...responsivePadding,
            maxWidth: MAX_WIDTH,
            margin: "0 auto",
            overflowY: "auto",
          }}
        >
          <StyledTypography
            variant="h5"
            sx={{
              fontWeight: "bold",
              margin: "2.5em 0 2em 0",
            }}
            align={"center"}
            width={"100%"}
          >
            Welcome to the CAI3p0 Internship Portal
          </StyledTypography>
          <StyledTypography
            variant="caption"
            sx={{ padding: "0 2em 0 2em", margin: ".5em 0 1em 0" }}
            align={"center"}
          >
            We're excited to have you on board for the CAI3p0 Applied AI and
            Entrepreneurship Program! To help you get started with your case
            study, we need some information from you.
          </StyledTypography>
          <List
            sx={{
              padding: "0 2em 0 2em",
              margin: "2em 0",
            }}
          >
            <InfoListItem
              title="Upload Your Documents"
              description="Please upload any relevant documents from the company you are working with that will assist in developing your case study."
            />
            <InfoListItem
              title="Answer a Few Questions"
              description="To better understand your project, please take a few moments to answer some questions. Your responses will help guide the development of your case study and ensure you're on the right track. If you upload any documents, we can assist you in finding insights within those materials."
            />
          </List>
          <StyledTypography
            variant="caption"
            sx={{ padding: "0 2em", margin: "2em 0" }}
            align={"center"}
          >
            Your contributions are vital to your success in this program, and
            we're here to support you every step of the way. If you need
            assistance at any time, please don't hesitate to reach out.
          </StyledTypography>
          <StyledTypography
            variant="caption"
            sx={{ padding: "0 2em", margin: "2em auto" }}
            align={"center"}
          >
            Let's get started!
          </StyledTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingLeftSide;
