import React from "react";
import { Menu } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Fab, Typography, useMediaQuery, useTheme } from "@mui/material";

const TopBar = ({ isDarkMode, title, icon, handleDrawerToggle }) => {
  const theme = useTheme();
  const colorModeStyle = isDarkMode ? "white" : "#333333";
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "26px",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        background: "#F3F4F6",
        alignContent: "flex-start",
        justifyContent: "space-between",
        borderBottom: "1px solid #E5E7EB",
        backgroundColor: isDarkMode ? "#1E1E1E" : "#F3F4F6",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: isMobileOrTablet ? "space-between" : "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            order: "0",
            flex: "0 1 auto",
            alignSelf: "center",
            margin: "0 1%",
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              size="2x"
              style={{ color: colorModeStyle, marginRight: "10px" }}
            />
          )}
        </Box>
        <Box
          sx={{
            order: "0",
            margin: "0 1%",
            flex: "0 1 auto",
            alignSelf: "auto",
          }}
        >
          <Typography
            sx={{
              width: "255px",
              overflow: "hidden",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              color: colorModeStyle,
              textOverflow: "ellipsis",
              maxWidth: isMobileOrTablet ? "calc(100% - 40px)" : "none",
            }}
          >
            {title}
          </Typography>
        </Box>
        {isMobileOrTablet ? (
          <Fab
            color="#F3F4F6"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              zIndex: 10,
              top: "16px",
              right: "16px",
              width: "40px",
              height: "40px",
              color: "#000000",
              boxShadow: "none",
              position: "absolute",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <Menu />
          </Fab>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default TopBar;
