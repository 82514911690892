import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { Auth, Storage } from "aws-amplify";
import { Scrollbars } from "react-custom-scrollbars-2";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRightAlt, CloudUpload } from "@mui/icons-material";
import Skip from "../../images/Skip.png";
// import Gears from "../../images/Gears.png";
import DataFetcher from "../../utils/DataFetcher";
import LoadingScreen from "./components/LoadingScreen";
import OnboardingLeftSide from "./components/OnboardingLeftSide";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

const OnboardingView = ({ userId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;

      Storage.configure({
        region: REGION,
        bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/chat");
      }, 8000);
    }
  }, [isLoading, navigate]);

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setErrorMessage("");
  };

  const onDropRejected = (rejectedFiles) => {
    const unsupportedFile = rejectedFiles[0];
    if (unsupportedFile) {
      setErrorMessage(
        `File ${unsupportedFile.file.name} is not a supported format. Only DOCX or PDF files.`
      );
    }
  };

  const removeFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  // const handleExtractionProcess = () => {
  //   onSelectOption();
  // };

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          console.log("filePath: ", filePath);
          console.log("file: ", file);
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          });
          console.log("applicantId: ", applicantId);
          console.log("result: ", result);
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      return uploadedFiles;
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    setDialogOpen(false);
    if (files.length > 0) {
      const uploadedFiles = await handleFileUpload(files, userId);
      await dataFetcher.saveFilesToDatabase(uploadedFiles, userId);
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          left: "50%",
          right: "50%",
          height: "100%",
          display: "flex",
          position: "fixed",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingScreen />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", backgroundColor: "#e0e0e0", padding: 2 }}
    >
      <Box
        width="80%"
        height="80vh"
        display="flex"
        flexDirection={isMobileOrTablet ? "column" : "row"}
        sx={{
          boxShadow: 3,
          borderRadius: "8px",
          backgroundColor: "#fff",
          overflow: isMobileOrTablet ? "auto" : "hidden",
        }}
      >
        <OnboardingLeftSide />
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          sx={{ backgroundColor: "#fff", padding: 2 }}
        >
          <Box
            {...getRootProps()}
            sx={{
              padding: 2,
              width: "50%",
              height: "90px",
              marginTop: "60px",
              cursor: "pointer",
              textAlign: "center",
              borderRadius: "4px",
              border: "2px dashed #ccc",
              backgroundColor: "#fafafa",
            }}
          >
            <IconButton
              sx={{
                marginTop: "6px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CloudUpload />
            </IconButton>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body1" sx={{ fontSize: "15px" }}>
                Drop the files here...
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ fontSize: isMobileOrTablet ? "10px" : "15px" }}
              >
                Click or drag file to this area to upload
              </Typography>
            )}
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <Typography
            variant={isMobileOrTablet ? "subtitle2" : "body2"}
            sx={{ color: "grey", marginTop: "20px" }}
          >
            Only DOCX or PDF files are allowed
          </Typography>
          <Box
            sx={{
              padding: 2,
              width: "50%",
              marginTop: "40px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              backgroundColor: "#fafafa",
            }}
          >
            <Typography
              gutterBottom
              fontWeight="bold"
              sx={{ color: "#015d86", fontSize: "13px" }}
            >
              Selected Documents
            </Typography>
            <Box sx={{ height: "150px" }}>
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                <List sx={{ overflowY: "auto" }}>
                  {files.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFile(file.name)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={file.name} />
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            </Box>
          </Box>
          <Box
            sx={{ width: isMobileOrTablet ? "90%" : "60%", marginTop: "10px" }}
          >
            <Typography
              variant={isMobileOrTablet ? "body1" : "h6"}
              gutterBottom
              fontWeight="bold"
              sx={{ marginTop: "60px", marginBottom: "20px" }}
            >
              Let's start!
            </Typography>
            {/* <Button
              color="primary"
              variant="contained"
              sx={{
                width: "100%",
                height: "70px",
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                "&:hover": {
                  "& .MuiIconButton-root": {
                    display: "block",
                  },
                  border: "1px solid #1976d2",
                  backgroundColor: "rgba(205, 209, 228, 0.3)",
                },
              }}
              onClick={handleExtractionProcess}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "45px",
                  display: "flex",
                  marginRight: "35px",
                }}
              >
                <img src={Gears} alt="App Logo" />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  color: "black",
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                <Typography
                  fontWeight="bold"
                  variant="subtitle2"
                  sx={{ fontSize: "16px" }}
                >
                  Start Extraction Process
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  I want to use the attached documents.
                </Typography>
              </Box>
              <IconButton
                sx={{
                  display: "none",
                  fontSize: "2rem",
                  marginLeft: "auto",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ArrowRightAlt />
              </IconButton>
            </Button> */}
            <Button
              color="primary"
              variant="contained"
              sx={{
                width: "100%",
                height: "70px",
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                backgroundColor: "#fff",
                justifyContent: "space-between",
                "&:hover": {
                  "& .MuiIconButton-root": {
                    display: "block",
                  },
                  border: "1px solid #1976d2",
                  backgroundColor: "rgba(205, 209, 228, 0.3)",
                },
              }}
              onClick={() => setDialogOpen(true)}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "45px",
                  display: "flex",
                  marginRight: "35px",
                }}
              >
                <img src={Skip} alt="App Logo" />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  color: "black",
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                <Typography
                  fontWeight="bold"
                  variant="subtitle2"
                  sx={{ fontSize: isMobileOrTablet ? "12px" : "16px" }}
                >
                  Start this process
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  I want to start from scratch.
                </Typography>
              </Box>
              <IconButton
                sx={{
                  display: "none",
                  fontSize: "2rem",
                  marginLeft: "auto",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ArrowRightAlt />
              </IconButton>
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { padding: "20px" } }}
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Skipping this process will force you to start your study case in a Conversation way!"
          }
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to start your process from scratch? Our
            document data extraction helps you to allow our assistant to work
            for you ;)
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCancel} color="error" variant="contained">
            Noooo! I want help!
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            autoFocus
            sx={{ backgroundColor: "#015d86" }}
          >
            Yes! Take me to chat
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OnboardingView;
