import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Lottie from "lottie-react";
import CheckAnimation from "../../utils/CheckAnimation.json";
import ErrorAnimation from "../../utils/ErrorAnimation.json";

const NotificationDialog = ({ open, onClose, title, message, type }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          padding: 1,
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <DialogTitle
          sx={{
            marginBottom: type === "success" ? "2px" : "-40px",
          }}
        >
          {title}
        </DialogTitle>
        <Lottie
          loop={false}
          animationData={type === "success" ? CheckAnimation : ErrorAnimation}
          style={{
            height: type === "success" ? 100 : 200,
          }}
        />
        <DialogContent
          sx={{
            marginTop: type === "success" ? "2px" : "-40px",
          }}
        >
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NotificationDialog;
