import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  faAddressCard,
  faArrowRightFromBracket,
  faChartColumn,
  faDatabase,
  faDice,
  faFileLines,
  faGraduationCap,
  faPenToSquare,
  faUserAstronaut,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { DarkMode, LightMode } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Sidebar.css";
import ProjectLogo from "../../images/ProjectLogo.png";

const drawerWidth = 240;
const coursesURL = "https://cai3p0-internship-program-community.circle.so";

const switchStyles = {
  "& .MuiSwitch-switchBase": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
};

const formControlLabelStyles = {
  width: "0%",
  marginLeft: "2px",
  "& .MuiTypography-root": {
    fontSize: "12px",
    marginTop: "3px",
  },
};

const Sidebar = ({
  isDarkMode,
  isAdmin,
  onQuestionClick,
  fromFullScreenChat,
  mobileOpen,
  handleDrawerToggle,
  handleSignOut,
  toggleTheme,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const colorModeStyle = isDarkMode ? "white" : "grey";

  const buttonStyles = {
    color: "#72757C",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "130%",
    fontStyle: "normal",
    fontFamily: "Inter",
    borderRadius: "8px",
    padding: "10px 16px",
    background: "#E5E7EB",
    textTransform: "inherit",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    "&:hover .icon-and-text": {
      color: isDarkMode ? "white" : "black",
      fontWeight: "550",
      transform: "translate(2px, -2px)",
    },
  };

  const handleRedirectClick = (route) => {
    onQuestionClick(null);
    navigate(route);
    if (isMobileOrTablet) {
      handleDrawerToggle();
    }
  };

  const menuItems = [
    {
      label: "Internship Assistant",
      icon: faUserAstronaut,
      route: "/chat",
      marginLeftText: "14px",
    },
    isAdmin && {
      label: "Admin Assistant",
      icon: faUserTie,
      route: "/admin-chat",
      marginLeftText: "15px",
    },
    isAdmin && {
      label: "Playground",
      icon: faDice,
      route: "/playground",
      marginLeftIcon: "-6px",
    },
    isAdmin && {
      label: "Prompts",
      icon: faPenToSquare,
      route: "/prompts",
      marginLeftIcon: "-1px",
      marginLeftText: "14px",
    },
    {
      label: "Case Study",
      icon: faFileLines,
      route: "/case-study",
      marginLeftIcon: "2px",
      marginLeftText: "17px",
    },
    {
      label: "Knowledge Base",
      icon: faDatabase,
      route: "/",
      marginLeftIcon: "1px",
      marginLeftText: "15px",
    },
    isAdmin && {
      label: "Customers",
      icon: faUsers,
      route: "/customers",
      marginLeftIcon: "-2px",
      marginLeftText: "10px",
    },
    isAdmin && {
      label: "Contacts",
      icon: faAddressCard,
      route: "/contacts",
      marginLeftIcon: "0px",
      marginLeftText: "11px",
    },
    isAdmin && {
      label: "Metrics",
      icon: faChartColumn,
      route: "/metrics",
      marginLeftIcon: "1px",
      marginLeftText: "14px",
    },
    {
      label: "Courses",
      icon: faGraduationCap,
      route: null, // If it's an external link
      external: true,
      marginLeftText: "9px",
    },
    {
      label: "Sign out",
      icon: faArrowRightFromBracket,
      action: handleSignOut,
      marginLeftIcon: "2px",
    },
  ].filter(Boolean);

  const drawer = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        position: "relative",
        borderBottom: "none",
        flexDirection: "column",
        backgroundColor: isDarkMode ? "#1E1E1E" : "#F3F4F6",
      }}
    >
      <Box
        className="logo-containter"
        sx={{
          width: "100%",
          backgroundColor: "#E5E7EB9",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            marginTop: "30px",
            marginBottom: "-20px",
            justifyContent: "center",
          }}
        >
          <img
            src={ProjectLogo}
            alt="App Logo"
            style={{ maxWidth: "55%", height: "55%" }}
          />
        </Box>
      </Box>
      <List sx={{ flexGrow: 1, marginTop: "revert" }}>
        {menuItems.map(
          (
            {
              label,
              icon,
              route,
              external,
              action,
              marginLeftText,
              marginLeftIcon,
            },
            index
          ) => (
            <ListItem key={index}>
              <Button
                sx={buttonStyles}
                fullWidth
                onClick={
                  action
                    ? action
                    : external
                    ? () => window.open(coursesURL, "_blank")
                    : () => handleRedirectClick(route)
                }
                startIcon={
                  <FontAwesomeIcon
                    className="icon-and-text"
                    icon={icon}
                    style={{
                      fontSize: "22px",
                      marginLeft: marginLeftIcon,
                      transition: "transform 0.3s ease, color 0.3s ease",
                    }}
                  />
                }
              >
                <Typography
                  variant="body1"
                  className="icon-and-text"
                  sx={{
                    fontSize: "0.9rem",
                    color: colorModeStyle,
                    marginLeft: marginLeftText || "12px",
                    transition: "transform 0.2s ease, color 0.2s ease",
                  }}
                >
                  {label}
                </Typography>
              </Button>
            </ListItem>
          )
        )}
      </List>
      {/* <Divider
        sx={{
          backgroundColor: isDarkMode ? "white" : "#BDC3C760",
        }}
      />
      <Box
        sx={{
          padding: "10px 16px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              sx={switchStyles}
              onChange={toggleTheme}
              checked={isDarkMode}
            />
          }
          sx={formControlLabelStyles}
        />
        <Button
          sx={{ textTransform: "none", color: "#1a749c" }}
          startIcon={isDarkMode ? <DarkMode /> : <LightMode />}
        >
          {isDarkMode ? "Dark" : "Light"}
        </Button>
      </Box> */}
    </Box>
  );

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        {<CssBaseline />}
        {isMobileOrTablet ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiDrawer-paper": {
                width: "15.8%",
                boxSizing: "border-box",
              },
            }}
            open
          >
            {fromFullScreenChat ? null : drawer}
          </Drawer>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Sidebar;
