import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";

const ConfirmSignUp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const confirmNewUser = async () => {
    try {
      await Auth.confirmSignUp(username, confirmCode);
      navigate("/signin");
    } catch (e) {
      setErrorMessage("An error occurred during the process.");
      console.error(e);
    }
  };

  const handleConfirmCodeChange = (event) => {
    setConfirmCode(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  return (
    <Box
      className="login-overlay"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        className="login-card"
        sx={{
          padding: 4,
          maxWidth: 500,
          borderRadius: 8,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            confirmNewUser();
          }}
          sx={{ mt: 2 }}
        >
          <Typography variant="h6" gutterBottom>
            Email Confirmation
          </Typography>
          <FontAwesomeIcon
            icon={faEnvelopeCircleCheck}
            style={{ fontSize: "40px", marginBottom: "15px" }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={username}
            onChange={handleUsernameChange}
          />
          <Typography variant="subtitle2" sx={{ marginTop: "10px" }}>
            Please, enter the invitation code that is attached to the email with
            this link.
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Confirmation Code"
            value={confirmCode}
            onChange={handleConfirmCodeChange}
          />
          {errorMessage && (
            <Typography variant="subtitle2" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={confirmNewUser}
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ConfirmSignUp;
