export const COLUMN_CUSTOMERS = [
  { id: "name", label: "Customer" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "domains", label: "Domains" },
];

export const CUSTOMER_DATA_VALUES = {
  id: "",
  name: "",
  phone: "",
  email: "",
  address: "",
  linkedin: "",
  domains: [],
  status: "Created",
};

export const CUSTOMER_FIELDS = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
    validate: (value) => (value.trim() ? "" : "Full name is required"),
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "text",
    validate: (value) => (/^\d{10}$/.test(value) ? "" : "Invalid phone number"),
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    validate: (value) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email address",
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    validate: (value) => (value.trim() ? "" : "Address is required"),
  },
];
