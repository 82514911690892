import React, { useState, useCallback, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Drawer,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { API } from "aws-amplify";
import { GridLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  COLUMN_CONTACTS,
  CONTACT_DATA_VALUES,
  CONTACT_FIELDS,
} from "./utils/constants";
import UserForm from "../../components/UserForm/UserForm";
import DataTable from "../../components/DataTable/DataTable";
import {
  deleteContact,
  getContact,
  getCustomer,
  putContact,
} from "../../graphql/queries";

const Contacts = () => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [alert, setAlert] = useState({ message: null, severity: "error" });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!contactsLoaded) {
      getContacts();
    }
  }, [contactsLoaded]);

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(search.toLowerCase())
  );

  const getContacts = async () => {
    try {
      const fetchedContacts = await fetchContacts();
      if (fetchedContacts?.length) {
        setContacts(fetchedContacts);
        setContactsLoaded(true);
      }
    } catch (error) {
      handleError("An error occurred while fetching contacts.");
    }
  };

  const fetchContacts = async () => {
    const params = { id: "", status: "" };
    try {
      const response = await API.graphql({
        query: getCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data.getCustomer.body);
    } catch (error) {
      throw new Error("Error fetching contacts");
    }
  };

  const handlePutContact = async (formData) => {
    const params = { ...CONTACT_DATA_VALUES, ...formData };
    try {
      const response = await API.graphql({
        query: putContact,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      handleAlert(response.data.putContact.body, "success");
    } catch (error) {
      handleError("An error occurred while creating/updating contact.");
    } finally {
      setContactsLoaded(false);
    }
  };

  const handleDeleteContact = async (contactId) => {
    const params = { id: contactId };
    try {
      const response = await API.graphql({
        query: deleteContact,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      handleAlert("Contact has been successfully removed.", "success");
      setContactsLoaded(false);
    } catch (error) {
      console.log("Error deleting contact: ", error);
      throw new Error("Error deleting contact: ", error);
    }
  };

  const handleAlert = (message, severity) => {
    setAlert({ message, severity });
  };

  const handleError = (errorMessage) => {
    handleAlert(errorMessage, "error");
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const openDrawerForEdit = (contact) => {
    setSelectedContact(contact);
    setDrawerOpen(true);
  };

  const openDrawerForNew = () => {
    setSelectedContact(null);
    setDrawerOpen(true);
  };

  return (
    <Box p={3}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "4vh" }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
      </Box>
      <Box display="flex" sx={{ gap: "15px" }}>
        <Button
          variant="contained"
          sx={{
            marginBottom: "20px",
            textTransform: "none",
            backgroundColor: "#015d86",
            "&:hover": { backgroundColor: "#1a749c" },
          }}
          startIcon={
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "16px" }} />
          }
          onClick={openDrawerForNew}
        >
          Add contact
        </Button>
      </Box>
      {contactsLoaded ? (
        <>
          <TextField
            value={search}
            variant="outlined"
            placeholder="Search"
            onChange={handleSearchChange}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
            sx={{
              marginRight: "40px",
              marginBottom: "10px",
              borderRadius: "25px",
              "& .MuiOutlinedInput-root": {
                width: "300%",
                height: "36px",
                fontSize: "13px",
                borderRadius: "20px",
                "&:hover fieldset": { borderColor: "black" },
                "&.Mui-focused fieldset": { borderColor: "black" },
              },
            }}
            InputProps={{
              startAdornment: !searchFocused && (
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginRight: "7px",
                  }}
                />
              ),
              sx: {
                fontSize: "13px",
                paddingLeft: "8px",
                borderRadius: "25px",
              },
            }}
          />
          <Typography variant="subtitle1" sx={{ margin: "1vh 0vh 1vh 0vh" }}>
            <strong>{contacts.length}</strong> in total
          </Typography>
          <DataTable
            type={"contacts"}
            data={filteredContacts}
            columns={COLUMN_CONTACTS}
            onEdit={openDrawerForEdit}
            onDelete={handleDeleteContact}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            marginTop: "20%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridLoader />
        </Box>
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <UserForm
          fields={CONTACT_FIELDS}
          initialData={selectedContact || {}}
          title={selectedContact ? "Edit Contact" : "New Contact"}
          submitButtonText={selectedContact ? "Update" : "Create"}
          onClose={() => setDrawerOpen(false)}
          onSubmit={handlePutContact}
        />
      </Drawer>
      <Snackbar
        open={!!alert.message}
        autoHideDuration={6000}
        onClose={() => setAlert({ message: null })}
      >
        <Alert
          onClose={() => setAlert({ message: null })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contacts;
