import React from "react";
import { TextField, Typography } from "@mui/material";
import { FIELD_HEIGHTS } from "../utils/constants";

const PromptDescriptionField = ({ description, onChange }) => (
  <>
    <Typography mb={-1} variant="body1" fontWeight="bold">
      Prompt Description
    </Typography>
    <TextField
      id="prompt-description"
      placeholder="Description"
      value={description}
      onChange={(e) => onChange(e, "description")}
      multiline
      rows={3}
      fullWidth
      variant="outlined"
      InputProps={{
        sx: { fontSize: "14px" },
      }}
      sx={{
        "& .MuiInputBase-root": { minHeight: FIELD_HEIGHTS.MULTILINE },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": { borderColor: "black" },
        },
      }}
    />
  </>
);

export default PromptDescriptionField;
