import React, { useCallback, useEffect, useState } from "react";
import mammoth from "mammoth";
import { PropagateLoader } from "react-spinners";
import { API, Auth, Storage } from "aws-amplify";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Box, Paper, Typography } from "@mui/material";
import { getFiles } from "../../graphql/queries";
import HtmlParser from "../../components/shared/HtmlParser";

const typeFile = "binary/octet-stream";

const CaseStudyViewer = ({ mode }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [filesLoaded, setFilesLoaded] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      setError("");
      setLoading(true);
      try {
        const user = await fetchUserId();
        if (user) {
          const applicantId = user.username;
          await fetchFiles(applicantId);
          setFilesLoaded(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  const fetchUserId = async () => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      throw new Error("Error fetching user ID");
    }
  };

  const fetchFiles = async (applicantId) => {
    try {
      const response = await API.graphql({
        query: getFiles,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { applicant_id: applicantId },
      });
      const fetchedFiles = JSON.parse(response.data.getFiles.body);
      setFiles(fetchedFiles);
    } catch (error) {
      throw new Error("Error fetching files");
    }
  };

  const filterFile = useCallback(() => {
    if (Array.isArray(files) && files.length > 0) {
      return files.find((file) => file.file_name.endsWith("case-study.docx"));
    }
    return null;
  }, [files]);

  const formatUrl = async (file) => {
    let filePath = file.s3_path;
    if (filePath.startsWith("public/")) {
      filePath = filePath.substring("public/".length);
    }
    return await Storage.get(filePath, { expires: 60 });
  };

  const parseContentToHTML = useCallback(async () => {
    const file = filterFile();
    if (!file) {
      setError(true);
      setHtmlContent(`
        <strong>It looks like you haven’t generated your case study yet.</strong>
        <p>To get started, please use the Internship Assistant and follow the steps to outline and develop your case study. The assistant will guide you through questions and help you with writing the document. If you need further assistance, feel free to reach out to our support team at internship-program@cai3p0.com. We’re here to help you succeed!</p>
      `);
      return;
    }

    const signedUrl = await formatUrl(file);
    const fileResponse = await fetch(signedUrl);
    if (!fileResponse.ok) {
      setError(true);
      setHtmlContent(`
        <strong>Failed to fetch file content: ${fileResponse.statusText}</strong>`);
      return;
    }

    const blob = await fileResponse.blob();
    if (blob.type !== typeFile) {
      setError(true);
      setHtmlContent(`Invalid file tyle`);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    try {
      const result = await mammoth.convertToHtml({ arrayBuffer });
      setHtmlContent(result.value);
    } catch (error) {
      setError(true);
      setHtmlContent(`Error converting DOCX to HTML: ${error}`);
    }
  }, [filterFile]);

  useEffect(() => {
    if (filesLoaded) {
      parseContentToHTML();
    }
  }, [filesLoaded, parseContentToHTML]);

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        margin: "60px",
        padding: "15px",
        flex: "1 1 auto",
        display: "block",
        maxHeight: "71%",
        borderRadius: "12px",
        alignItems: "baseline",
        border: "1px solid grey",
        justifyContent: "center",
        boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: mode === "dark" ? "#2C2C2C" : "#F3F4F6",
      }}
    >
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <PropagateLoader />
          </Box>
        )}
        {!loading && htmlContent && (
          <Box>
            {!error && (
              <Typography variant="h4" sx={{ padding: "15px" }}>
                Case Study:
              </Typography>
            )}
            <HtmlParser html={htmlContent} />
          </Box>
        )}
      </Scrollbars>
    </Paper>
  );
};

export default CaseStudyViewer;
